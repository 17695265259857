
import { Component, Vue } from "vue-property-decorator";

interface Dir {
  id: number;
  name: string;
}

@Component
export default class MyDocumentsComponent extends Vue {
  dirs: Dir[] = [];

  mounted() {
    this.$store.dispatch("documents/dirs").then(response => {
      this.dirs = response.data.data;
    });
  }
}
